<template>
  <div class="mainbox">
    <div class="header">
      <div @click="$router.go(-1)">
        <svg-icon class="navArrow" iconClass="navArrow" />
      </div>
      <div class="title">{{ deatil.title }}</div>
    </div>
    <div class="content overflow-y-auto">
      <!-- 视频 -->
      <div class="cover" v-if="type == 'video'" @click="showPlayVideo = true">
        <ImgDecypt :src="deatil.cover" class="modCover">
          <div class="play_box">
            <svg-icon iconClass="playIcon"></svg-icon>
          </div>
        </ImgDecypt>
      </div>
      <!-- 图片 -->
      <div class="changImage" v-if="type == 'image'">
        <ImgDecypt :src="deatil.cover" class="modCover" />
      </div>
      <div class="mess">
        <h2>注意事项：</h2>
        <p>
          1.
          选择一张人脸清晰，不得有任何遮挡的照片上传（注意：只含一个人物和脸部，图片不能过暗）
        </p>
        <p>
          2.
          选择一个心仪的视频或图片模板，点击生成，生成时间需要3-5分钟，耐心等待。（图片模板可自行上传）
        </p>
        <p>
          3.
          在右上角记录查看生成进度，生成成功后可以点击进行下载，也可以在线观看。
        </p>
        <p>4. 按照上方操作，有问题随时联系在线客服进行处理。</p>
        <p>5. 不支持多人图片，禁止未成年人图片</p>
      </div>
      <div class="imgmess">
        <div>
          <img src="@/assets/png/aiTip1.png" alt="" />
          正面无遮挡
        </div>
        <div>
          <img src="@/assets/png/aiTip2.png" alt="" />
          不遮挡脸部
        </div>
        <div>
          <img src="@/assets/png/aiTip3.png" alt="" />
          不遮挡眼睛
        </div>
      </div>
      <div class="title">上传脸部信息</div>
      <div @click.stop>
        <van-uploader
          v-model="fileList"
          :after-read="afterRead"
          :before-read="beforeRead"
          :before-delete="beforeDelete"
        >
          <template #default>
            <div class="uploadBox flex-center-center">
              <img src="@/assets/png/aiUploadBg.png" alt="" />
              图片大小低于2MB
            </div>
          </template>
          <template #preview-delete>
            <svg-icon
              iconClass="previewDelete"
              class="previewDelete"
            ></svg-icon>
          </template>
        </van-uploader>
      </div>
    </div>
    <div class="bottom" v-if="type == 'video'">
      <!-- <span v-if="this.type == 'video'">
        消耗金币:
        {{ this.userInfo.isVip ? this.deatil.vipCoin : this.deatil.coin }}
      </span>
      <span v-else>
        消耗金币:
        {{ this.aiUndressPrice || 0 }}
      </span>
      <span v-if="this.type == 'image'"
        >免费次数：{{ this.freeCount || 0 }}</span
      > -->
      <div class="amountBox">
        <div class="payAmount">
          消耗金豆:
          {{ this.userInfo.isVip ? this.deatil.vipCoin : this.deatil.coin }}
        </div>
        <div class="couponBox" v-if="couponNum">
          <div class="couponNum">AI换脸券: ({{ couponNum }})</div>
          <div class="useBtn" @click="showCoupons">立即使用</div>
        </div>
      </div>
      <div class="btn" @click="onSubmit">金豆支付</div>
    </div>
    <div class="imageBtnBox" v-else-if="type == 'image'">
      <div class="btn" @click="onSubmit">金豆支付</div>
      <div class="payInfo">
        <div class="payAmount">
          消耗金豆:
          {{ this.userInfo.isVip ? this.deatil.vipCoin : this.deatil.coin }}
        </div>
        <div class="freeCount">
          免费次数: {{ walletInfo.aiUndressFreeTimes || 0 }}
        </div>
      </div>
    </div>

    <van-overlay :show="this.showPlayVideo" z-index="9999">
      <svg-icon
        iconClass="navArrow"
        class="close"
        @click="this.showPlayVideo = false"
      ></svg-icon>
      <div class="wrapper blackBg">
        <SmallVideo
          v-if="this.showPlayVideo"
          class="videoBox"
          :sourceURL="this.deatil.sourceURL"
          :key="this.deatil.sourceURL"
        />
      </div>
    </van-overlay>
    <van-popup
      v-model="showCouponList"
      position="bottom"
      :overlay="false"
      @close="closePop"
    >
      <div class="couponPop">
        <div class="couponTitle">
          <div class="popTitle">使用兑换券</div>
          <div @click="showCouponList = false">
            <svg-icon iconClass="popClose" class="popClose" />
          </div>
        </div>
        <div class="couponList">
          <PullRefresh
            :loading="loading"
            :refreshing="refreshing"
            :finished="finished"
            @onLoad="onLoad"
            @onRefresh="onRefresh"
            :isHigehtMax="true"
            :isNoData="isNoData"
            :error="error"
          >
            <div
              class="couponItem"
              v-for="item in list"
              :key="item.id"
              @click="selectCoupon(item.id)"
            >
              <div class="couponName">
                {{ item.goodsValue }}金豆{{ item.goodsName }}
              </div>
              <div
                class="selectIcon"
                :class="{ selectedIcon: item.id == couponId }"
              ></div>
            </div>
          </PullRefresh>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Toast, Uploader } from "vant";
// import { uploadImg } from "@/api/app";
import {
  generateImg,
  queryBackpack,
  uploadFile,
  generateChangeVideo,
  generateChangeImg,
} from "@/api/mine";
import { mapGetters } from "vuex";
import ImgDecypt from "@/components/ImgDecypt";
import SmallVideo from "@/components/SmallVideo";
import PullRefresh from "@/components/PullRefresh";
export default {
  name: "changeDetail",
  components: {
    ImgDecypt,
    [Uploader.name]: Uploader,
    SmallVideo,
    PullRefresh,
  },
  data() {
    return {
      showPlayVideo: false,
      uploadImages: [],
      fileSize: 500 * 1024,
      fileList: [],
      freeCount: 0,
      totalPrice: 0, // ai脱衣总售价
      aiUndressPrice: 0, // ai脱衣的售价
      deatil: {},
      timer: "",
      type: "",
      list: [],
      pageNumber: 1,
      pageSize: 10,
      loading: true,
      refreshing: false,
      finished: false,
      error: false,
      isNoData: false,
      couponNum: 0,
      showCouponList: false,
      couponId: "",
      // walletInfo: {},
    };
  },
  computed: {
    ...mapGetters({
      walletInfo: "walletInfo",
      userInfo: "userInfo",
    }),
    balance() {
      return (this.walletInfo?.amount || 0).toFixed(1);
    },
  },
  created() {
    this.aiUndressPrice = this.$store.getters.appConfig.aiUndressPrice || 0;
    this.freeCount = this.userInfo.aiUndressCount || 0;
  },
  mounted() {
    this.getDeatil("refresh");
    if (this.$route.query.type == "video") {
      this.queryCoupons();
    } else {
      this.queryBalance();
    }
  },
  methods: {
    beforeRead(file) {
      if (file.size > this.fileSize) {
        Toast("文件不可超过2MB");
        return false;
      }
      return true;
    },
    // 获取详情
    async getDeatil() {
      this.deatil = JSON.parse(localStorage.getItem("deatilData"));
      this.type = this.$route.query.type;
    },
    // 查询钱包
    async queryBalance() {
      await this.$store.dispatch("user/getWallet");
      // this.walletInfo = this.$store.getters.walletInfo;
    },
    // 获取详情
    async queryCoupons(type) {
      let req = {
        page: this.pageNumber,
        limit: this.pageSize,
        status: 2,
        goodsType: 4,
        // uid: 224411
      };
      try {
        let res = await this.$Api(queryBackpack, req);
        this.loading = false;
        this.refreshing = false;
        if (res.code === 200) {
          let list = res.data.list || [];
          this.couponNum = res.data.total || 0;
          if (type === "refresh") {
            this.list = list;
          } else {
            this.list = this.list.concat(list);
          }
          if (this.list.length <= 0) {
            this.isNoData = true;
          }
          if (list.length < this.pageSize) {
            this.finished = true;
            return;
          }
          // console.log(this.isNoData, "==-=-=-==============");
        } else {
          this.error = true;
        }
      } catch (error) {
        this.error = true;
        this.loading = false;
      }
    },
    selectCoupon(id) {
      this.couponId = id;
    },
    onRefresh() {
      this.pageNumber = 1;
      this.refreshing = true;
      this.finished = false;
      this.loading = true;
      this.queryCoupons("refresh");
    },
    onLoad() {
      this.loading = true;
      if (this.error) {
        this.error = false;
      } else {
        this.pageNumber++;
      }
      this.queryCoupons();
    },
    showCoupons() {
      this.showCouponList = true;
    },
    closePop() {
      this.couponId = "";
    },
    // 图片上传
    async afterRead(file) {
      if (file.length) {
        file.map(async (item) => {
          let newFile = await this.compress(item.file);
          item.file = newFile;
          await this.uploadImg(item);
        });
      } else {
        let newFile = await this.compress(file.file);
        file.file = newFile;
        this.uploadImg(file);
      }
    },
    //图片上传
    async uploadImg(fileInfo) {
      let req = new FormData();
      req.append("upload", fileInfo.file);
      fileInfo.status = "uploading";
      fileInfo.message = "上传中...";
      try {
        let ret = await this.$Api(uploadFile, req);
        const { data } = ret;
        fileInfo.status = "done";
        fileInfo.message = "上传成功";
        fileInfo.status = "";
        fileInfo.message = "";
        this.uploadImages.push(data.coverImg);
        this.totalPrice = this.showPrice(
          this.uploadImages.length,
          this.aiUndressPrice
        );
      } catch (e) {
        fileInfo.status = "failed";
        fileInfo.message = "上传失败";
      }
    },
    // 压缩图片
    compress(file) {
      let selt = this;
      if (!file || !window.FileReader) return;
      if (/^image/.test(file.type)) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        return new Promise((resolve, reject) => {
          reader.onloadend = function () {
            let result = reader.result;
            let img = new Image();
            img.src = result;
            if (result.length <= 200 * 1024) {
              let blob = selt.convertBase64ToBlob(result);
              let files = new File([blob], file.name, { type: blob.type });
              resolve(files);
              return;
            }
            img.onload = function () {
              let canvas = document.createElement("canvas");
              let ctx = canvas.getContext("2d");
              let width = img.width;
              let height = img.height;
              canvas.width = width;
              canvas.height = height;
              // 铺底色
              ctx.fillStyle = "#fff";
              ctx.fillRect(0, 0, canvas.width, canvas.height);
              ctx.drawImage(img, 0, 0, width, height);
              //进行最小压缩
              let ndata = canvas.toDataURL("image/jpeg", 0.3);
              let blob = selt.convertBase64ToBlob(ndata);
              let files = new File([blob], file.name, { type: blob.type });
              resolve(files);
            };
          };
        });
      }
    },
    //将base64转换为文件对象
    convertBase64ToBlob(base64) {
      var base64Arr = base64.split(",");
      var imgtype = "";
      var base64String = "";
      if (base64Arr.length > 1) {
        //如果是图片base64，去掉头信息
        base64String = base64Arr[1];
        imgtype = base64Arr[0].substring(
          base64Arr[0].indexOf(":") + 1,
          base64Arr[0].indexOf(";")
        );
      }
      // 将base64解码
      var bytes = atob(base64String);
      //var bytes = base64;
      var bytesCode = new ArrayBuffer(bytes.length);
      // 转换为类型化数组
      var byteArray = new Uint8Array(bytesCode);

      // 将base64转换为ascii码
      for (var i = 0; i < bytes.length; i++) {
        byteArray[i] = bytes.charCodeAt(i);
      }
      // 生成Blob对象（文件对象）
      return new Blob([bytesCode], { type: imgtype });
    },
    // 获取脱衣后的图片
    async getGenerateImg() {
      if (!this.userInfo.isVip) return Toast("请先充值vip");
      if (!this.uploadImages.length) return Toast("至少上传一张图片");
      let req = {
        originPic: this.uploadImages[0],
        mId: this.deatil.id,
        coin: this.totalPrice,
      };
      try {
        let res = await this.$Api(generateChangeImg, req);
        this.queryBalance();
        Toast("上传成功，等待审核");
        this.fileList = [];
        this.uploadImages = [];
        if (this.freeCount <= 0) {
          this.walletInfo.amount -= this.totalPrice;
        }
        this.totalPrice = 0;
      } catch (e) {
        console.log(e);
      }
    },
    // async getGenerateImg() {
    //   if (!this.userInfo.isVip) {
    //     this.$bus.$emit("vipPopup", {
    //       this: 1,
    //       closeBtn: () => {
    //         this.$bus.$emit("closeVipPopup");
    //       },
    //     });
    //     return;
    //   }
    //   if (!this.uploadImages) return Toast("请先上传一张图片");
    //   if (this.freeCount <= 0 && Number(this.aiUndressPrice) > this.balance)
    //     return Toast("免费次数与账户余额不足");
    //   let req = {
    //     originPic: this.uploadImages[0],
    //     mId: this.deatil.id,
    //    coin: this.totalPrice,
    //    // coin: Number(this.aiUndressPrice),
    //   };
    //   let res = await this.$Api(generateChangeImg, req);
    //   if (res && res.code == 200) {
    //     Toast("上传成功，等待审核");
    //    this.fileList = [];
    //    this.uploadImages = "";
    //     if (this.freeCount > 0) {
    //       this.freeCount--;
    //     }
    //   } else {
    //     Toast(res.tip || "上传失败");
    //   }
    // },
    // 根据索引删除数组
    delElbyIndex(arr, index) {
      for (let i = index, len = arr.length - 1; i < len; i++) {
        arr[i] = arr[i + 1];
        arr.length = len;
      }
      return arr;
    },
    async beforeDelete(file, detail) {
      const newArr = await this.delElbyIndex(this.uploadImages, detail.index);
      this.totalPrice = 0; //showPrice(newArr.length, this.aiUndressPrice);
      this.uploadImages = newArr;
      return true;
    },
    // 价格优惠
    showPrice(quantity, price) {
      let totalPrice = 0;
      totalPrice = quantity * price;
      return Math.ceil(totalPrice);
    },
    // ai视频
    async getGenerateVideo() {
      if (!this.uploadImages.length) return Toast("至少上传一张图片");
      if (!this.userInfo.isVip) return Toast("请先充值vip");
      let req = {
        picture: this.uploadImages,
        vidModId: this.deatil.id,
        discount: this.couponId ? [this.couponId] : undefined,
      };
      try {
        let res = await this.$Api(generateChangeVideo, req);
        if (res && res.code == 200) {
          Toast("上传成功，等待审核");
          this.fileList = [];
          this.uploadImages = [];
          this.walletInfo.amount -= this.totalPrice;
          this.totalPrice = 0;
          this.showCouponList = false;
          this.queryCoupons("refresh");
        } else {
          Toast(res.tip || "上传失败，请稍后再试");
        }
      } catch (e) {
        console.log(e);
      }
    },
    onSubmit() {
      this.debounce(
        this.type == "video" ? this.getGenerateVideo : this.getGenerateImg,
        500
      );
    },
    debounce(fn, wait) {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        fn();
      }, wait);
    },
  },
};
</script>

<style lang="scss" scoped>
.mainbox {
  width: 100%;
  height: 100%;
}
.header {
  height: 44px;
  padding: 0 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  background: #fff;
  .back {
    width: 30px;
    height: 30px;
  }
  .title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #333333;
    font-size: 18px;
    width: 285px;
    font-weight: 600;
    margin-left: 20px;
    text-align: center;
  }
}
.content {
  overflow: auto;
  height: calc(100vh - 44px);
  background: #f1f1f1;
  width: 100%;
  padding: 0 10px 200px 10px;
  box-sizing: border-box;
  .changImage {
    width: 100%;
    height: 225px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 12px;
    .modCover {
      width: 150px;
      height: 100%;
      /deep/ img {
        border-radius: 8px;
      }
    }
  }
  .cover {
    width: 100%;
    height: 193px;
    margin: 16px 0;
    .modCover {
      width: 100%;
      height: 100%;
      position: relative;
      /deep/ img {
        border-radius: 8px;
      }
    }
    .play_box {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      > svg {
        width: 50px;
        height: 50px;
      }
    }
  }
  .mess {
    h2 {
      color: #333333;
      font-family: "Dream Han Sans TC";
      font-size: 16px;
      font-style: normal;
      font-weight: 900;
      line-height: 22px;
      margin: 8px 0;
    }
    p {
      font-family: "PingFang SC";
      font-size: 12px;
      color: #999;
    }
  }
  .imgmess {
    margin: 19px 0;
    padding: 0 10px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    > div {
      font-size: 14px;
      color: rgb(153, 153, 153);
      img {
        width: 100px;
        height: 100px;
        // margin-top: 14px;
      }
    }
  }
  .title {
    margin-bottom: 14px;
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    font-family: "Dream Han Sans TC";
    font-weight: 900;
    line-height: 22px;
  }
}
/deep/ .van-uploader {
  width: 111px;
  height: 111px;
  .van-uploader__wrapper {
    width: 100%;
    height: 100%;
    border: dashed 1px #999999;
    border-radius: 5px;
    overflow: hidden;
    background: #ebebeb;
  }
  .van-uploader__input-wrapper,
  .uploadBox {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 30px;
      height: 30px;
      margin-bottom: 10px;
    }
    font-size: 12px;
    color: #999999;
  }
}
.precautionsBox {
  h2 {
    font-size: 14px;
    color: #333333;
    margin: 6px 0;
    font-weight: 500;
  }
  margin-left: 20px;
  font-size: 12px;
  line-height: 16.8px;
  color: #999999;
}
.close {
  height: 30px;
  width: 30px;
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 10000;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 30px;
  height: 100%;
  width: 100%;
  background: rgb(0, 0, 0);
  .videoBox {
    height: 100%;
    width: 100%;
  }
}
.bottom {
  width: 100%;
  height: 91px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px 0 10px;
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  left: 0;
  background: #fafafa;
  z-index: 10;
  .amountBox {
    .payAmount {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 5px;
    }
    .couponBox {
      display: flex;
      align-items: center;
      .couponNum {
        color: #555;
        font-family: "PingFang SC";
        font-size: 10px;
        line-height: 16px;
        margin-right: 5px;
      }
      .useBtn {
        color: #814cff;
        font-family: "PingFang SC";
        font-size: 10px;
        line-height: 16px; /* 160% */
      }
    }
  }
  .btn {
    width: 147px;
    height: 49px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    background: #fff;
    box-shadow: -5px -5px 20px 0px #fff,
      5px 5px 20px 0px rgba(174, 174, 192, 0.5);
    color: #b09fd9;
    font-family: "Dream Han Sans TC";
    font-size: 16px;
    font-style: normal;
    font-weight: 900;
    line-height: 22px;
  }
  > span {
    color: #333333;
    font-size: 14px;
  }
}
.imageBtnBox {
  width: 100%;
  height: 133px;
  position: fixed;
  bottom: 0;
  left: 0;
  background: #fafafa;
  z-index: 10;
  padding-top: 18px;
  box-sizing: border-box;
  .btn {
    width: 333px;
    height: 49px;
    border-radius: 30px;
    background: #fff;
    box-shadow: -5px -5px 20px 0px #fff,
      5px 5px 20px 0px rgba(174, 174, 192, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 20px;
    color: #b09fd9;
    font-family: "Dream Han Sans TC";
    font-size: 16px;
    font-style: normal;
    font-weight: 900;
    line-height: 22px;
  }
  .payInfo {
    color: #333;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "PingFang SC";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    .payAmount {
      margin-right: 30px;
    }
  }
}
/deep/ .van-uploader__preview {
  width: 116px;
  height: 116px;
  border-radius: 4px;
  overflow: hidden;
  margin: unset;
  .van-uploader__preview-image {
    width: 100%;
    height: 100%;
  }
}
.couponPop {
  height: 200px;
  width: 100%;
  background: #fafafa;
  border-radius: 8px;
  overflow: hidden;
  padding: 14px 10px;
  box-sizing: border-box;
  .couponTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    .popTitle {
      font-family: "Dream Han Sans TC";
      font-size: 16px;
      font-weight: 900;
      line-height: 22px;
    }
    .popClose {
      width: 16px;
      height: 16px;
    }
  }
  .couponList {
    height: 150px;
    .couponItem {
      width: 100%;
      height: 40px;
      margin-bottom: 10px;
      padding: 0 14px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 2px solid rgba(0, 0, 0, 0.05);
      border-radius: 8px;

      .couponName {
        color: #b09fd9;
        font-family: "Dream Han Sans TC";
        font-size: 14px;
        font-weight: 900;
        line-height: 20px;
      }
      .selectIcon {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background: #fff;
        border: 1px solid #814cff;
      }
      .selectedIcon {
        background: #814cff;
      }
    }
  }
}
/deep/.van-popup--bottom {
  bottom: 88px !important;
  z-index: 9 !important;
  background: rgba(0, 0, 0, 0);
  border-radius: 8px;
  border: 2px rgba(0, 0, 0, 0.05);
  background: #fff;
}
/deep/ .isNoData {
  display: none;
}
</style>
